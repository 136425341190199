import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Helmet from 'react-helmet'

const ContactForm = () => {
    return (
        <section className="contact-area pb-100">
            <Helmet><script src="https://www.google.com/recaptcha/api.js" async defer></script></Helmet>
            <div className="container">
                <div className="section-title">
                    <h2>Пратете ни порака</h2>
                    <p>Вашата e-mail адреса нема да биде објавена.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6 col-md-12">
                        <div className="contact-image">
                            <StaticImage src={'../../assets/images/contact.svg'}
                                alt="Person next to envelope."
                                formats={["AUTO", "WEBP", "AVIF"]}
                                placeholder="none"
                                loading="eager" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="contact-form">
                            <form id="contactForm" action="https://formspree.io/f/xeqvlagd" method="post">
                                <div className="row">
                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="text" name="name" className="form-control" required placeholder="Вашето име" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-6">
                                        <div className="form-group">
                                            <input type="email" name="_replyto" className="form-control" required placeholder="Вашата email адреса" />
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea name="message" className="form-control" cols="30" rows="6" required placeholder="Порака..." />
                                            <div class="g-recaptcha" data-sitekey="6Lf49JEbAAAAAA2i8fIFSLFiwjZLHnIXnEZYho9o"></div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12">
                                        <button type="submit" className="default-btn">
                                            Прати Порака <span></span>
                                        </button>
                                    </div>
                                </div>
                            </form>
                            {/* <form onSubmit={handleSubmit}>
                                <label htmlFor="email">Email</label>
                                <input type="text" name="email" id="email" {...email} required />
                                <label htmlFor="message">Message</label>
                                <textarea name="message" id="message" rows="4" {...message} required></textarea>
                                <input type="submit" value="Send" />
                            </form> */}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactForm